<template>
  <div class="procurePayReview">
    <h1>审核采购付款信息</h1>
    <el-form class="form" label-width="auto">
      <el-form-item>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="danger" @click="review('unpass')">不通过</el-button>
          <el-button type="success" @click="review('pass')">通过</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="请填写审核备注：">
        <el-input v-model="data.procurePay.reviewNote" :max="100" />
      </el-form-item>
      <el-form-item label="供应商">
        <el-text>{{ data.procurePay.supplier }}</el-text>
      </el-form-item>
      <el-form-item label="申请日期">
        <el-text>{{ data.procurePay.applyDate }}</el-text>
      </el-form-item>
      <el-form-item label="用途">
        <el-text>{{ data.procurePay.useto }}</el-text>
      </el-form-item>
      <el-form-item label="金额">
        <el-text>{{ data.procurePay.amount }}</el-text>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'

const router = useRouter()
const pageParam = cache.getObject(cache.keys.pageParam + "procurePayReview")
const data = reactive({
  procurePay: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {},
  file: {}
})

const review = (reviewState) => {
  const param = { id: data.procurePay.id }
  param.reviewNote = data.procurePay.reviewNote
  param.reviewState = reviewState
  api.post('/backend/procurePay/review', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}
</script>

<style lang="less">
.procurePayReview {
  margin: auto;
  max-width: 900px;
}
</style>